import { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import { accountFetch, accountUpdate } from "../../classes/database/Accounts";
import { contentFetch, contentUpdate } from "../../classes/database/Content";
import { debounce, dateRange, weeksBetween, getWeekNumber, months, days } from "../../helpers/functions";

import SocialFeedback from "./SocialFeedback";
import SocialDisplay from "./SocialDisplay";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";
import GoogleDriveUploader from "./GoogleDriveUploader";

import PDFViewer from "../PDFViewer";

function PreviewContent(props) {
	const { id, planningId, postDate, postIndex } = useParams();
    const [data, setData] = useState();
    const [activeDays, setActiveDays] = useState();
    const [datePanel, setDatePanel] = useState(false);
    const [viewAllItems, setViewAllItems] = useState('open');
    const [expanded, setExpanded] = useState({});
    const [upload, setUpload] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(null);
    const [activeSocial, setActiveSocial] = useState([]);

    let counter = 0;

    const statuses = {
        "input" : [
            'Input verzamelen',
            'Feedback op input (1)',
            'Feedback op input verwerken',
            'Feedback op input (2)',
            'Feedback op input verwerken (2)',
            'Content maken',
        ],
        "content" : [
            'Input verzamelen',
            'Content maken',
            'Feedback op content (1)',
            'Feedback op content verwerken',
            'Feedback op content (2)',
            'Feedback op content verwerken (2)',
            'Content ingepland'
        ]
    };

    const accounts = props.collections.accounts
    ? props.collections.accounts
    : {};
    const setAccounts = (items) => {
        props.setAccounts(items);
    };
    
    useEffect(async () => {
		props.setCurrentPosts(null);
		props.setCurrentLeads(null);


		const user = accounts[id] ? accounts[id] : await accountFetch(id);
		props.setCurrentAccount(user);
		if (!accounts[id]) {
			const _accounts = { ...accounts };
			_accounts[id] = user;
			props.setAccounts(_accounts);
		}
		return () => {
			props.setCurrentAccount(null);
			props.setCurrentLeads([]);
			props.setCurrentPosts([]);
		};
	}, []);
    
    useEffect(async () => {
        const plans = await contentFetch(id, planningId);
        if(!plans){ return; }
        setData(plans);
    }, []);
    
    useEffect(() => {
        if(!data){ return; }
        if(currentMonth){ return; }
        setCurrentMonth(new Date(data.startPeriod.seconds * 1000));
        setActiveDays(dateRange(
            new Date(data.startPeriod.seconds * 1000),
            new Date(data.endPeriod.seconds * 1000),
            true
        ));
    }, [data]);

    useEffect(() => {
        setTimeout(() => {
            const items = document.querySelectorAll('.zd__social__description > p');
            for(let i = 0; i < items.length; i++){
                const child = items[i].querySelector('span');
                if(child && child.clientHeight > items[i].clientHeight){ continue; }
                const button = items[i].parentNode.querySelector('.show__more');
                if(!button){ continue; }
                button.click();
            }
        }, 50);
    },[data]);

    useEffect(() => {
        if(!data){ return; }
        if(data.items){ return; }

        const items = createDefaultItems();
        const numberOfWeeks = weeksBetween(
            new Date(data.startPeriod.seconds * 1000),
            new Date(data.endPeriod.seconds * 1000)
        );
        
        const numberOfPosts = numberOfWeeks * data.frequency;
        const plannedPosts = items.length;
        const newData = {...data, items : items, 'numberOfPosts' : numberOfPosts, 'plannedPosts' : plannedPosts};
        contentUpdate(planningId, newData, id);
        setData(newData);
    }, [activeDays]);

    const updateDriveId = async (id, key) => {
        if(!id){ return; }

        let idStore = null;
        if(!key){
            idStore = id;
        } else if (typeof data.driveFolderId === 'string'){
            idStore = {
                "base" : data.driveFolderId
            };
            idStore[key] = id;
        } else {
            idStore = { ...data.driveFolderId }
            idStore[key] = id;
        }
        
        const newData = {
            ...data,
            driveFolderId: idStore
        }
        await contentUpdate(planningId, newData, id);
        setData(newData);
    };

    const debouncedHandler = useCallback(
		debounce(contentUpdate, 500),
		[contentUpdate]
	  );

      
    
    const pingPDF = async (file) => {
        if(!file){ return; }
        // setIsLoading(true);
        const response = await refetchContent();
        const text = await response.text();

        if(text.indexOf(props.file) > 0){        
            // setIsLoading(false);
        } else {
            setTimeout(()=>{pingPDF(file)}, 2000);
        }

    }
    
    const pingFile = async ({ currentTarget }) => {
        if(!currentTarget){ return; }
        // setIsLoading(true);
        currentTarget.classList.add('image__404');
        const response = await refetchContent();
        const text = await response.text();
        if(text.indexOf(props.file) > 0){        
            currentTarget.onerror = null;
            currentTarget.src = props.file;
            currentTarget.classList.remove('image__404');
            // setIsLoading(false);
        } else {
            setTimeout(()=>{pingFile({'currentTarget': currentTarget})}, 2000);
        }

    }

    const createDefaultItems = () => {
        const filtered = {};
        let size = 0;
        activeDays.filter((item, key) => {
            if(item.getDay() == data.placement.first){
                filtered[item.getFullYear()+""+item.getMonth()+""+item.getDate()] = {
                    date : new Date(item),
                    posts:[],
                    prePlanned: true
                };
                size++;
            } else if(item.getDay() == data.placement.second){
                let include = false;
                if(data.onceEveryOtherWeek == 'even'){
                    include = getWeekNumber(item) % 2 == 0 ? true : false;
                } else if(data.onceEveryOtherWeek == 'odd'){
                    include = getWeekNumber(item) % 2 == 1 ? true : false;
                }

                if(data.frequency == 2 || include){
                    filtered[item.getFullYear()+""+item.getMonth()+""+item.getDate()] = {
                        date : new Date(item),
                        posts:[],
                        prePlanned: true
                    };
                    size++;
                }
            }
        });
        filtered.length = size;
        return filtered;
    }

    const displaySidebar = () => {
        return outputDatePanel();
    }

    const outputDate = (date) => {
		return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
	}

    const updatePostObject = async (e) => {
        let key = null;
        if(datePanel){
            key = datePanel.getFullYear()+""+datePanel.getMonth()+""+datePanel.getDate();
        } else if(e.target.dataset.date){
            key = e.target.dataset.date;
        }
        const newData = {...data};
        if(newData.items[key] && typeof newData.items[key].isEmpty != 'undefined'){
            delete newData.items[key].isEmpty;
        }
        
        if(e.target.name == "image"){
            newData.items[key].posts[e.target.dataset.index][e.target.name] = e.target.value;

            const version = newData.items[key].posts[e.target.dataset.index]['version'];
            newData.items[key].posts[e.target.dataset.index]['version'] = version ? version + 1 : 1;
        }else if(e.target.name == "file-feedback"){
            newData.items[key].posts[e.target.dataset.index]['feedback'].push({
                "message": `Bestand: ${e.target.value.name}`,
                "driveLink":  `https://drive.google.com/file/d/${e.target.value.id}/view`,
                "reactions" : [],
                "userId" : props.current.account.id,
                "userName" : props.current.account.name
            });
        } else if(e.target.name == "file-input"){
            newData.items[key].posts[e.target.dataset.index]['input'].push({
                "message": `Bestand: ${e.target.value.name}`,
                "driveLink":  `https://drive.google.com/file/d/${e.target.value.id}/view`,
                "reactions" : [],
                "userId" : props.current.account.id,
                "userName" : props.current.account.name
            });
        } else {
            newData.items[key].posts[e.target.dataset.index][e.target.name] = e.target.value;
        }

        debouncedHandler(planningId, newData, id);
        setData(newData);
    }

    const refetchContent = async () => {
        const response = await fetch("https://socialrss.zodoende.nl/feed/Contentplanningen/"+planningId);
        return response;
    }

    const outputModeSwitcher = (item, index) => {
        return (
            <div className="post__mode__switcher">
                <button className={item.postMode != "content" ? "active" : ""} onClick={(e)=>updatePostObject({"target":{"dataset":{"index":index},"name": "postMode", "value": "input"}})}>Input</button>
                <button className={item.postMode == "content" ? "active" : ""} onClick={(e)=>updatePostObject({"target":{"dataset":{"index":index},"name": "postMode", "value": "content"}})}>Content</button>
            </div>
        );
    }

    const outputPostInformation = (item, index, baseDir, date = datePanel) => {
        if(postDate && postIndex){ return null; }
        const currentStatusPosition = statuses.input.indexOf(item.status);
        const dateKey = date.getFullYear()+""+date.getMonth()+""+date.getDate()+"-"+index;
        
        if(currentStatusPosition >= 0 && currentStatusPosition != statuses.input.length - 1){
            return (
                <div key={`input_${baseDir}`} className="zd__feedback__panel">
                    {outputModeSwitcher(item, dateKey)}
                    <SocialFeedback placeholder="input" data={item.input} index={dateKey} update={(value, key) => updatePostObject({"target":{"dataset":{"index":key, "date" : dateKey},"name": "input", "value": value}})}>
                        <button className="zd__attachment" onClick={()=> setUpload(dateKey)}></button>
                        {(upload === dateKey && <GoogleDriveUploader cancelable={() => setUpload(false)} directory={[baseDir, "input"]} name={null} update={(file) => { setUpload(false); updatePostObject({"target":{"dataset":{"index":index, "date" : date.getFullYear()+""+date.getMonth()+""+date.getDate()},"name": "file-input", "value": file}}); }} driveFolderId={data.driveFolderId} storeDriveFolderId={updateDriveId} title={data.title}>
                        </GoogleDriveUploader>)}
                    </SocialFeedback>
                </div>
            );
        }

        return (
            <div key={`feedback_${baseDir}`} className="zd__feedback__panel">
                {outputModeSwitcher(item, dateKey)}
                <SocialFeedback placeholder="feedback" data={item.feedback} index={dateKey} update={(value, key) => updatePostObject({"target":{"dataset":{"index":key, "date" : dateKey},"name": "feedback", "value": value}})}>
                    <button className="zd__attachment" onClick={()=> setUpload(dateKey)}></button>
                    {(upload === dateKey && <GoogleDriveUploader cancelable={() => setUpload(false)} directory={[baseDir, "feedback"]} name={null} update={(file) => { setUpload(false); updatePostObject({"target":{"dataset":{"index":index, "date" : date.getFullYear()+""+date.getMonth()+""+date.getDate()},"name": "file-feedback", "value": file}}); }} driveFolderId={data.driveFolderId} storeDriveFolderId={updateDriveId} title={data.title}>
                    </GoogleDriveUploader>)}
                </SocialFeedback>
            </div>
        );
    }

    const outputContentView = (item, index, date, difference, baseDir, filePath, social) => {
        const channels = props.current.account.channels;
        const currentStatusPosition = statuses.input.indexOf(item.status);

        if(social == 'Articles'){
            return <SocialDisplay
                editable={false}
                key={index}
                item={item}
                index={index}
                datePanel={date} 
                minimal={postDate && postIndex ? true : false}
                activeSocial={social}
                userId={id}
                planningId={planningId}
                refetchContent={refetchContent}
                updatePostObject={function(){ return null; }}
                outputPostInformation={function(){ return null; }}
                updateDriveId={function(){ return null; }}
                data={data}
                previewContent={()=>{ return filePath ? outputFiles(filePath) : null; }}
            />;
        }

        return channels.map((object, key) => {
            if(object.active == false){ return null; }
            if(object.name != social){ return null; } 

            if(currentStatusPosition >= 0 && currentStatusPosition != statuses.input.length - 1){
                return (<div key={`social_${key}_${index}`} className={`zd__social linkedin${difference < 0  ? ' has__passed' : ''}`}>
                    <div key={0} className="zd__social__head grid">
                        <div className="zd__social__details grid two">
                            <strong>{item.title}</strong>
                            <span>{outputDate(date)} - {
                            difference > 0 ? 
                                (difference == 1 ? `morgen` : `over ${Math.abs(difference)} dagen`) : 
                            difference == 0 ? `vandaag` : 
                                (difference == -1 ? `gisteren`  : `${Math.abs(difference)} dagen geleden`)}</span>
                        </div>
                    </div>
                    <div key={1} className={`zd__social__description${expanded[baseDir] === true ? ' expand' : ''}`}>
                        <h2 className="needs__title">Benodigdheden</h2>
                        <p style={{whiteSpace:"pre"}}>{item.needs && item.needs.length > 0 ? item.needs : "De benodigdheden zijn nog onbekent."}</p>
                    </div>
                </div>    );
            }

            return <SocialDisplay
                editable={false}
                key={index}
                item={item}
                index={index}
                minimal={postDate && postIndex ? true : false}
                datePanel={date} 
                activeSocial={social}
                userId={id}
                planningId={planningId}
                refetchContent={refetchContent}
                updatePostObject={function(){ return null; }}
                outputPostInformation={function(){ return null; }}
                updateDriveId={function(){ return null; }}
                data={data}
                previewContent={()=>{ return filePath ? outputFiles(filePath) : null; }}
            />;
            return ( <div key={`social_${index}`} className={`zd__social linkedin${difference < 0  ? ' has__passed' : ''}`}>
                <div key={0} className="zd__social__head grid two">
                    <div className="zd__column">
                        <span className="zd__social__logo"></span>
                    </div>
                    <div className="zd__social__details">
                        <strong>{props.current.account.company}</strong>
                        {props.current.account.followers && <span>{props.current.account.followers} volgers</span>}
                        <span>{outputDate(date)} - {
                        difference > 0 ? 
                            (difference == 1 ? `morgen` : `over ${Math.abs(difference)} dagen`) : 
                        difference == 0 ? `vandaag` : 
                            (difference == -1 ? `gisteren`  : `${Math.abs(difference)} dagen geleden`)}</span>
                    </div>
                </div>
                <div key={1} className={`zd__social__description${expanded[baseDir] === true ? ' expand' : ''}`}>
                    <p><span>{item.description}</span></p>
                    {expanded[baseDir] === true ? null : <span onClick={(e) => { const newExpanded = {...expanded}; newExpanded[baseDir] = true; setExpanded(newExpanded); }} key={1} className="show__more">...lees meer</span>}
                </div>
                <div key={2} className={`zd__social__image`}>
                    {(filePath != null && <div className="zd__image__overlay">
                        {outputFiles(filePath)}
                        {/* <img onError={refetchContent} src={filePath}/> */}
                    </div>)}
                </div>
                <div key={3} className="zd__social__actions">
                    <span className="zd__social__logo"></span>
                    <span className="zd__social__like">Like</span>
                    <span className="zd__social__comment">Comment</span>
                    <span className="zd__social__repost">Repost</span>
                    <span className="zd__social__send">Send</span>
                </div>
            </div>);
        });
    }

    const outputFiles = (file) => {
        const extension = file.split(/\./).pop();
        const imageValidExtensions = ["jpg","bmp","gif","png"];
        const videoValidExtensions = ['m4v', 'avi','mpg','mp4', 'webm', 'mov']; 

        if(file.indexOf('.pdf') == file.length - 4){
            return (<PDFViewer onError={pingPDF} file={file} />);
        } else if(imageValidExtensions.indexOf(extension) >= 0) {
            return <img onError={pingFile} src={file}/>;
        } else if(videoValidExtensions.indexOf(extension) >= 0){
            return (<video  src={file} controls onError={pingFile}></video>);
        } else {
            alert("Bestandsformaat wordt niet ondersteund (jpg, bmp, gif, png, m4v, avi,mpg, mp4, webm, mov).");
        }
    }

    const outputSocialItems = (item, index, date, social)=>{    
        const difference = (Math.round(getDaysBetween(new Date(), date)));
        const baseDir = `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} key-${index}`;
        
        const activeKey = `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} key-${index}`;
        const filePath = (!item.image || !item.image[social] || !item.image[social].src || !item.image[social].src.name) ? null : 
            (item.image[social].src.name.indexOf('.pdf') != item.image[social].src.name.length - 4) ? `https://socialrss.zodoende.nl/content/Contentplanningen/${planningId}/${item.image[social].src.name}` : 
            `https://socialrss.zodoende.nl/pdfViewer/content/Contentplanningen/${planningId}/${item.image[social].src.name}`;

        const channels = props.current.account.channels;
        let currentActive = channels.filter((object, key) => {
            const activeKey = `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} key-${index}`;
            return activeSocial[activeKey] == object.name;
        });

        if(currentActive.length == 0 && activeSocial[activeKey] == 'Articles'){
            currentActive = [{name :"Articles", active:true}]
        } else if(currentActive.length == 0){
            channels.filter((object, key) => {
                if(object.active){
                    currentActive.push(object);
                }
                return true;
            });
        }

        return ([
            outputContentView(item, index, date, difference, baseDir, filePath, currentActive[0].name),
            (difference >= 0 ? outputPostInformation(item, index, baseDir, date) : <div key={baseDir} className="empty__feedback"></div>)
        ]);
    }

    const outputPost = (item, index, date) => { 

        
        if(postDate && outputDate(date) != postDate){
            return null;
        }

        if(postIndex && postIndex != index){
            return null;
        }
        // if(viewAllItems == 'open'){
            if((!item.description || item.description.length == 0) && (!item.image || !item.image.name || item.image.name.length == 0)){
                return;
            }
        // }

        counter++;
   
        const difference = (Math.round(getDaysBetween(new Date(), date)));
        if(viewAllItems == 'open' && difference < 0){
            return null;
        }
        const channels = props.current.account.channels;
        let currentActive = channels.filter((object, key) => {
            const activeKey = `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} key-${index}`;
            return activeSocial[activeKey] == object.name;
        });
        const currentStatusPosition = statuses.input.indexOf(item.status);
        const inPlanningPhase = !(currentStatusPosition >= 0 && currentStatusPosition != statuses.input.length - 1);
        
        if(currentActive.length === 0){
            if(activeSocial[`${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} key-${index}`] == 'Articles'){
                currentActive = [{name: "Articles"}];
            } else {
                channels.filter((object, key) => {
                    if(currentActive.length == 0 && object.active){
                        currentActive.push(object);
                    }
                });
            }
        }

        return (<div key={index} className={`post__item preview${postDate && postIndex ? ' minimal' : ''}`}>
                    {inPlanningPhase && <div className="grid six">
                    <button key={-1} onClick={()=>{
                            const activeKey = `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} key-${index}`;
                            const temp = {...activeSocial};
                            temp[activeKey] = 'Articles';
                            setActiveSocial(temp);
                        }} className={`social__switch${item.image && item.image['Articles'] ? '' : ' inactive'}${currentActive && currentActive[0] && currentActive[0].name == 'Articles' ? ' active' : ''}`} disabled={item.image && item.image['Articles'] ? false : true}>Artikel</button>    
                    {channels.map((object, key) => {
                        if((postDate && postIndex) && !object.active){
                            return null;
                        }
                        const activeKey = `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} key-${index}`;

                        if(currentActive.length == 0){
                            currentActive.push({name : object.name})
                        }

                        return <button key={key} onClick={()=>{
                            const temp = {...activeSocial};
                            temp[activeKey] = object.name;
                            setActiveSocial(temp);
                        }} className={`social__switch${object.active ? '' : ' inactive'}${currentActive[0].name == object.name ? ' active' : ''}`} disabled={object.active ? false : true}>{object.name}</button>
                    })}</div>}
                <div key={0} className="post__inner__container">
                {outputSocialItems(item, index, date, currentActive[0].name)}
            </div>
        </div>);
    }

    const outputDatePanel = () => {
        const keys = Object.keys(data.items).sort((a,b)=>{
            if(a == 'length' || b == 'length'){return false;}
            const dateA = (data.items[a].date.seconds ? new Date(data.items[a].date.seconds * 1000) : data.items[a].date);
            const dateB = (data.items[b].date.seconds ? new Date(data.items[b].date.seconds * 1000) : data.items[b].date);
            return dateA < dateB ? -1 : 1;
        });
        
        const list = keys.map((index, _index)=>{
            if(!data.items[index] || index == 'length'){
                return null;
            }
            const item = data.items[index];
            
            return ([
               item.posts.map((i,k)=>outputPost(i,k,new Date(item.date.seconds * 1000)))   
            ]);
        });
        return list;
    }
    
    const getDaysBetween = (date1, date2) => {
        var Difference_In_Time = date2.getTime() - date1.getTime();
        return Difference_In_Time / (1000 * 3600 * 24);
    }

    const outputView = () => {
        if(!data){ return null; }
        if(!data.items){ return null; }
        
        return (
            <section key={0} className="edit__content__planning__container">
                <div key={0} className="container">
                    <h2 key={0}>
                        {data.title}
                    </h2>
                    {displaySidebar()}
                    {(!postDate && !postIndex) &&(
                        (counter === 0 && <p>Er zijn geen posts gevonden voor deze content planning</p>)
                        (counter > 0 && <div className="toggle__hide__container finished__post">
                            <div className="slider__container">
                                <span className="zd__label">{viewAllItems == 'open' ? "Toekomstige posts" : "Alle posts"}</span>
                                <input name="view__items" onChange={(e)=>setViewAllItems(e.target.value)} type="radio" value="open" checked=""/>
                                <input name="view__items" onChange={(e)=>setViewAllItems(e.target.value)} type="radio" value="done"/>
                                <span className="finished__slider"></span>
                            </div>
                        </div>)
                    )}
                </div>
            </section>
        );
    }
    
    return outputView();
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewContent);
