import firebase from "./firebase";
import { getServerTimeStamp } from "./firebase";
import {
	collection,
	doc,
	getDoc,
	addDoc,
    setDoc,
	updateDoc,
	getDocs,
	query,
	where,
	orderBy,
	deleteDoc,
	documentId,
} from "firebase/firestore";

import { shallowEqual } from "../../helpers/functions";

const buildRef = (date) => {
	return collection(firebase, "NotificationPlanner", date, "List");
};
export const plannerRef = collection(firebase, "NotificationPlanner");

export const plannerInsert = async (date, planningId, index, item) => {
	if (typeof date == "undefined" || date == null) {
		return false;
	}
    const id = `${planningId}-${index}`;
	const ref = doc(firebase, "NotificationPlanner", date, "List", id);
	//plannerRef
	const docRef = await setDoc(ref, {
		created: getServerTimeStamp(),
		date: date,
        ...item
	});
	return await getDoc(ref);
};
export const plannerFetchAllDate = async (date) => {
	if (!documentId) {
		return;
	}
	const ref = collection(firebase, "NotificationPlanner", date);
	// plannerRef
	let q = query(ref, orderBy("created", "desc"));
	// q = query(q, where("owner", "==", "/Accounts/" + documentId));

	const snapshot = await getDocs(q);
	const data = [];
	snapshot.forEach((element) => {
		data.push({
			...element.data(),
			documentId: element.id,
		});
	});

	if (data.length > 0) {
		return data;
	}
	return false;
};

export const plannerUpdate = async (documentId, data, date) => {
	var documentRef = doc(firebase, "NotificationPlanner", date, "List", documentId);
	const result = await updateDoc(documentRef, data);
	return {
		data: {
			...data,
			documentId: documentId,
		},
	};
};

export const plannerDelete = async (date, id) => {
	try {
	    const documentRef = doc(firebase, "NotificationPlanner", date, "List", id);
		const response = await deleteDoc(documentRef);
	} catch(e){
		console.log(e);
	}
};

export const plannerExists = async (query) => {
	const snapshot = await getDocs(query);
	const data = [];
	snapshot.forEach((element) => {
		data.push({
			...element.data(),
			documentId: element.id,
		});
	});

	if (data.length > 0) {
		return data;
	}
	return false;
};
